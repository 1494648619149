<template>
  <v-footer app bottom fixed padless>

    <v-bottom-sheet
        inset
    >

      <template v-slot:activator="{ on, attrs }">
        <v-container class="pa-0">
          <v-spacer></v-spacer>
          <v-btn
              :ripple="false"
              color="#821FC5"
              class="secondary--text no-background-hover transparent"
              v-bind="attrs"
              v-on="on"
              width="150"
              height="55"
              large
              rounded
              style="position: fixed;bottom: 20px; margin-left: -80px"
          >
            <video id="vid3" src="@/assets/Triangle.mp4" autoplay loop muted playsinline></video>
          </v-btn>
          <v-spacer></v-spacer>

        </v-container>

      </template>
      <v-card
          class="text-center pb-3"
          min-height="100"
          dark
      >
        <v-row>
          <v-col cols="4">
            <v-icon size="32px" color="primary">
              mdi-phone
            </v-icon>
            <span class="secondary--text"> <a href="tel:+33614854190" class="secondary--text">+33(0) 6 14 85 41 90</a> </span>
          </v-col>

          <v-col cols="4">
            <v-icon size="32px" color="primary">
              mdi-email
            </v-icon>
            <span class="secondary--text"><a href="mailto:quentin.veillas@telecom-sudparis.eu" class="secondary--text ml-1">quentin.veillas@telecom-sudparis.eu</a> </span>
          </v-col>


          <v-col cols="4">
            <v-icon size="32px" color="primary">
              mdi-linkedin
            </v-icon>
            <a
                class="secondary--text"
                href="https://www.linkedin.com/in/quentin-veillas"
                target="_blank">
              www.linkedin.com/in/quentin-veillas </a>
          </v-col>
        </v-row>
        <v-divider class="ml-6 mr-6 mt-2 mb-3"/>

        <span class="secondary--text my-auto">Website designed & produced by <p class="jude">Quentin Veillas</p> © {{ new Date().getFullYear() }} </span>


      </v-card>
    </v-bottom-sheet>

    <!--    {{ new Date().getFullYear() }} — <strong>VeillasInc </strong>-->
  </v-footer>
</template>

<script>
export default {
  name: "Footer",

  computed: {

    options () {
      return {
        duration: 4000,
      }
    },

  },

  mounted() {
    const span = document.querySelector("span");

    span.onclick = function() {
      document.execCommand("copy");
    }

    span.addEventListener("copy", function(event) {
      event.preventDefault();
      if (event.clipboardData) {
        event.clipboardData.setData("text/plain", span.textContent);
        console.log(event.clipboardData.getData("text"))
      }
    });

  }
}




</script>

<style scoped>

.jude{
  font-family: LeckerliOne, sans-serif;
  display: inline;
  font-size: 20px;
}

.no-background-hover::before {
  background-color: transparent !important;
}

a { text-decoration: none; }

</style>
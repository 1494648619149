<template>
  <v-app>
    <NavBar/>
    <v-main>
      <router-view style="z-index: 1"/>
    </v-main>
    <Footer/>
  </v-app>
</template>

<script>
import Router from '@/router/router';
import Footer from '@/components/Footer';
import NavBar from '@/components/NavBar';
//import HelloWorld from './components/FR.vue'

export default {
  name: 'App',

  components:{
    Footer,
    NavBar,
  },
  data: ()=>({
    Router,
  }),
}
</script>

<style>


#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
</style>

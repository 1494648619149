import Vue from 'vue'
import App from './App.vue'
import Router from '@/router/router'
import vuetify from './plugins/vuetify';


Vue.config.productionTip = false

new Vue({
  router: Router,
  vuetify,
  render: h => h(App),
}).$mount('#app')

<template onclick="this.resetMethod()">

  <div class="hello">

    <!--    Background    -->
    <iframe class="backG" v-if="this.$vuetify.breakpoint.height>820" src='https://my.spline.design/dany-5c65bffe981d4e7ee6d8e656cf569c09/' frameborder='0' width='100%' height='100%' ></iframe>
    <iframe class="backG" v-if="this.$vuetify.breakpoint.height<821" src='https://my.spline.design/danycopy-db328c8a05109def1aaf102d52b2bdef/' frameborder='0' width='100%' height='100%'></iframe>
    <!--    &lt;!&ndash;    Menu     &ndash;&gt;-->
    <!--    <v-card class="myfont circle mx-auto"> Click me</v-card>-->
    <!--    <v-card class="myfont circle mx-auto" id="hiddenCircle"> Click me</v-card>-->


    <v-card class="myfont text--secondary text-right transparent" width="100" style="position: absolute; right: 3px; top:-105px;z-index: 15; box-shadow: none !important;">
      <v-card-text style="box-shadow: none !important;">
        <span color="#ffffff" @click="$router.replace('/')" style="font-size: 16px;" class="lang white--text ">FR </span>
        <span color="#ffffff" style="font-size: 23px;" class="white--text ">|</span>
        <span color="#ffffff" @click="$router.replace('/en')" style="font-size: 17px;" class="lang white--text "> <b> EN</b></span>
      </v-card-text>
    </v-card>
    <!--    videos   -->
    <v-container v-if="this.$vuetify.breakpoint.height>820" >
      <v-spacer/>
      <v-btn
          :ripple="false"
          rounded
          color="#821FC5"
          height="55"
          width="170"
          large
          @click="hiddenTopLeftMethod"
          class="mt-5 secondary--text transparent no-background-hover"
          style="position: fixed;top: 85px;margin-left: -220px;"
      >
        <video id="vid1"  src="@/assets/Cube.mp4" type="video/mp4" autoplay loop muted playsinline></video>
      </v-btn>
      <v-spacer/>
      <v-btn
          :ripple="false"
          rounded
          color="#821FC5"
          height="55"
          width="153"
          @click="hiddenTopRightMethod"
          class="mt-5 secondary--text transparent no-background-hover"
          large
          style="position: fixed;top: 85px;margin-left: 80px"
      >
        <video id="vid2"  src="@/assets/Icosahedron.mp4" type="video/mp4" autoplay loop muted playsinline></video>
      </v-btn>
      <v-spacer/>
    </v-container>

    <!--    mobile videos  -->
    <v-container v-else >
      <v-spacer/>
      <v-btn
          :ripple="false"
          rounded
          color="#821FC5"
          height="55"
          width="170"
          large
          @click="hiddenTopLeftMethod"
          class="mt-5 secondary--text transparent no-background-hover"
          style="position: fixed;top: 85px;margin-left: -170px;"
      >
        <video id="vid1m"  src="@/assets/Cube.mp4" type="video/mp4" autoplay loop muted playsinline></video>
      </v-btn>
      <v-spacer/>
      <v-btn
          :ripple="false"
          rounded
          color="#821FC5"
          height="55"
          width="153"
          @click="hiddenTopRightMethod"
          class="mt-5  secondary--text transparent no-background-hover"
          large
          style="position: fixed;top: 85px;margin-left: 10px"
      >
        <video id="vid2m"  src="@/assets/Icosahedron.mp4" type="video/mp4" autoplay loop muted playsinline></video>
      </v-btn>
      <v-spacer/>
    </v-container>


    <!--    Qui suis-je ?    -->
    <v-expand-transition>
      <v-card
          color="primary"
          v-show="hiddenTopLeft"
          min-width="300"
          max-width="600"
          :max-height="this.$vuetify.breakpoint.height-170"
          content="start"
          class="pb-3 mt-3 mr-2 scroll myFont"
          style="
         opacity: 0.95;
         position: fixed;top: 150px ;left: 10px;
         z-index: 4;
        "
      >
        <v-card-title style="display: inline-block; word-break: break-word;"><h2 class="myFont">Who am I ? <br>Quentin Veillas</h2></v-card-title>
        <v-card-text>
          A <b>passion for video games</b>, an <b>overflowing motivation</b>, a <b>desire to do well</b> and a <b>quick learning curve</b>.
          These are the elements that, through my <b>engineering training</b> at <b>Télécom Saint-Étienne</b>
          (with work shared with students from Design, Métiers d'Art and Industry) and <b>MEDIA </b>
          (<b>Management of digital & media projects</b>) at <b>Télécom SudParis and Institut Mines-Télécom Business
          School, </b>, allowed me to develop my skills in engineering, management and design.
        </v-card-text>
        <v-card-text>
          During my studies, I was in charge of Gaming at Télécom Saint-Étienne, which allowed me to <b>organize e-sport
          events</b> during one year and to <b>be aware of gamers' expectations and to make researches about
          the video game market</b>. The Global Game Jam 2021 opened my eyes:<b> the Game Design</b> is made for me.

        </v-card-text>
        <v-card-text>
          <b> Designer, developer, ambitious and artist</b>: that's how I describe myself, and why the job of <b>Game Designer</b>
          suits me.
        </v-card-text>

        <v-row v-if="this.$vuetify.breakpoint.width>500">
          <v-col cols="6" >
            <v-hover>
              <template v-slot:default="{ hover }">
                <v-card
                    elevation="15"
                    min-width="212"
                    max-width="212"
                    min-height="300"
                    max-height="300"
                    class="mx-auto">
                  <v-img
                      v-show="hiddenTopLeft"
                      width="212" height="300"
                      src="@/assets/LFrench CV Quentin VEILLAS.jpg">
                    <v-fade-transition>
                      <v-overlay
                          v-if="hover"
                          :opacity="0.8"
                          absolute
                          color="#1A1A1A"
                      >
                        <v-btn
                            href="Quentin_VEILLAS_Resume.pdf"
                            class="ma-2"
                            outlined
                            download>
                          Download Resume
                        </v-btn>
                      </v-overlay>
                    </v-fade-transition>
                  </v-img>
                </v-card>

              </template>
            </v-hover>
          </v-col>

          <v-col cols="6">
            <Modal :image="image" style="width: 212px; height: 300px;" class="mx-auto"/>
          </v-col>
        </v-row>

        <v-row v-else>
          <v-col cols="6" >
            <v-hover>
              <template v-slot:default="{ hover }">
                <v-card
                    elevation="15"
                    min-width="160"
                    max-width="160"
                    min-height="250"
                    max-height="250"
                    class="mx-auto ml-2">
                  <v-img
                      v-show="hiddenTopLeft"
                      width="160" height="250"
                      src="@/assets/LFrench CV Quentin VEILLAS.jpg">
                    <v-fade-transition>
                      <v-overlay
                          v-if="hover"
                          :opacity="0.8"
                          absolute
                          color="#1A1A1A"
                      >
                        <v-btn
                            href="Quentin_VEILLAS_Resume.pdf"
                            class="ma-2"
                            outlined
                            dense
                            width="150"
                            small
                            download>
                          Download Resume
                        </v-btn>
                      </v-overlay>
                    </v-fade-transition>
                  </v-img>
                </v-card>

              </template>
            </v-hover>
          </v-col>

          <v-col cols="6"  v-if="this.$vuetify.breakpoint.width>500">
            <Modal :image="image" style="width: 212px; height: 300px;" class="mx-auto"/>
          </v-col>
          <v-col cols="6"  v-else>
            <Modal :image="image" style="width: 140px; height: 250px;" class="mx-auto"/>
          </v-col>
        </v-row>
      </v-card>
    </v-expand-transition>


    <!--    Mes projets    -->
    <v-expand-x-transition v-if="(this.$vuetify.breakpoint.height/this.$vuetify.breakpoint.width)<1">
      <v-card
          color="primary"
          v-show="hiddenTopRight"
          max-width="1200"
          min-height="400"
          :max-height="this.$vuetify.breakpoint.height-245"
          content="start"
          class="pb-3 mt-3 ml-2 scroll pr-2 myFont"
          shaped
          style="
         background-color: rgba(255, 255, 255, 0) !important;
         position: fixed;top: 150px ;right: 10px;
         z-index: 4;
        "
      >
        <v-card
            color="primary"
            class="myFont"
            :width="this.$vuetify.breakpoint.width*0.4"
        >
          <v-card-title style="justify-content: center; word-break: break-word;"><h2 class="myFont mt-0">My Projects and Achievements</h2></v-card-title>
        </v-card>

        <v-card
            color="primary"
            class="mt-5 myFont"
        >
          <v-card-title style="word-break: break-word;"><h3 class="myFont mt-0">Shidôkan : The Labyrinth</h3> </v-card-title>
          <v-row align="center"
                 justify="center">
            <v-col cols="6" class="text-left">
              <v-card-text>
                January 2021, we started an adventure proposed by the <b>Global
                Game Jam</b>. Composed of a <b>team of 6 people</b>: three developers, a Game Artist,
                a Sound Designer and myself as Game Designer, we have <b>48 hours</b> to make a
                game around the theme <i>"Lost & Found"</i>. The machine is set in motion, after
                creating a context and a scenario, the project starts and we create a
                complete <b>Unity 2D mobile game.</b> <br><br> <u>But how did we make a unique maze game?</u>
                We have created a game with <b>an evolving gameplay including shortcuts creation, weapon
                unlock, and in-game level changes</b>, random terrain
                generation, more than thirty unique sounds and 4 musics.<br>
                The overall atmosphere of the game becomes more and more stressful and threatening, which will have
                implied choices of colors and music that vary during the progression of the game.<br>
                In addition, bridging the gap between game design and development was very exciting.<br>
                Using digital tools such as <b>Trello</b>, <b>GitLab</b> and <b>Discord</b>, the game is fully
                developed with <b>Unity</b> in <b>C#</b> is finished 8 hours before the deadline. A rich,
                exciting and emotional experience!
                <v-row>
                  <v-col cols="6">
                    <br><a class="text--primary" target="_blank" href="https://globalgamejam.org/2021/games/shid%C3%B4kan-le-labyrinth-6">See the game on the GGJ website.</a>
                    <br><a class="text--primary" target="_blank" href="https://quentin-veillas.itch.io/shidokan-le-labyrinthe">Visit the Itch.io page of the game.</a>
                  </v-col>
                  <v-col cols="5">
                    <v-btn
                        href="Shidôkan_Le_Labyrinthe.apk"
                        class="ma-2 mt-6 ml-n5"
                        outlined
                        download>
                      Download the game's APK
                    </v-btn>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-col>
            <v-col cols="6" class="mt-n6">
              <v-row>
                <v-col cols="5">
                  <modal :image="GGJLogo"/>
                </v-col>
                <v-col cols="6">
                  <modal :image="GGJSC1" class="mr-5 mt-8"/>
                </v-col>
                <v-col cols="6">
                  <modal :image="GGJLequipe" class="mx-auto pt-5"/>
                </v-col>
                <v-col cols="5" class="pt-n5">
                  <modalVideo :image="GameplayGGJ" class="pt-5 mt-n5"/>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-card>

        <v-card
            color="primary"
            class="mt-8 myFont"
        >
          <v-card-title style=" word-break: break-word;"><h3 class="myFont mt-0">Biometric Bifurcation Simulator</h3></v-card-title>
          <br>
          <v-row>
            <v-col cols="6" class="ml-5 my-auto">
              <v-row>
                <v-col cols="5">
                  <modal :image="SBBSceno"/>
                  <modal :image="SBBFinal" class="pt-8 mr-n5" style="max-width: 300px"/>
                </v-col>
                <v-col cols="7">
                  <v-row class="mt-n6 my-auto">
                    <v-col cols="12">
                      <modal :image="SBBGif" class="ml-n5" style="max-width: 350px !important; width: auto !important;"/>
                    </v-col>
                    <v-col cols="8" class="mx-auto">
                      <modal :image="SBBTableauDeBord" />
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="5" class="text-right">
              <v-card-text class="mt-n8">
                <b><i>"How to create a museum simulation piece where several members of the public can interact and
                  evolve a population
                  of fantasy beings over half a day?"</i></b> <br> <br>
                <b> For 6 months</b>, a <b>team of 9 people</b>, including 4 students from <i>Design, Arts and Industry</i>
                and 5 engineers, worked hand in hand to create a <b>museum piece</b> for the Saint-Etienne Biennial.<br><br>

                <u>The Design of the Game has been well thought out and worked on:</u> the spectators must follow the evolution
                of a population of supernatural creatures following choices made. <b>When the participant sees the work
                for
                the first time, they see it upside down. Only one sentence is right-side up.
                The participant has to answer the question asked by passing to the left or the right of the work. Once
                the decision is made, the participant sees the work "the right way round" and sees the evolution of the
                population live, according to the chosen answer.</b><br><br>
                It is thus a simulation which counts the participation of about <b>fifteen participants</b> in each
                reboot, and about ten possible ends according to the choices of the participants. The project was
                realized by associating several technologies such as <b>Unreal Engine 4</b>, <b>Gitlab</b>, <b>Trello</b>,
                <b>Three.js</b>, <b>Blender</b> and <b>Discord</b>.
              </v-card-text>
            </v-col>
          </v-row>
        </v-card>
        <v-card
            color="primary"
            class="mt-8 myFont"
        >
          <v-card-title style=" word-break: break-word;"><h3 class="myFont mt-0">Cube Adventures - my first video game project</h3></v-card-title>
          <v-row>
            <v-col cols="8" class="text-left">
              <v-card-text>
                At the end of 2020, an idea came into my head: <b><i>"what if I used my free time to create my own video game?"</i></b><br>
                So I started with a developer friend on <b>Unity</b> and another Sound Designer friend, my role was to be the
                <b>Game Designer</b>, but also the <b>3D Game Artist</b>. <br>The scenario is written and we start on a <b>3D
                RPG </b> in the style of Cube World, but revisited with several worlds accessible, several themes and atmospheres.
                The player explores an open world where he can discover villages, wild enemies, and dungeons.
                <br><br>
                <u>Game and Level Design:</u> <b>The player explores</b> an <b>open world</b> where he can discover
                villages, wild enemies, and dungeons.
                Within these dungeons are series of enemies and a final boss with a unique power. <b>When the boss is defeated,
                a teleportation portal to a new world appears</b>, and <b>the player gains the power of this boss that
                will
                allow him/her
                to survive in this new world</b> (e.g. the power to fly in an aerial world or to breathe indefinitely in a
                water world).
                These new powers can also allow, in the manner of a <i>Metroidvania</i>, to access inaccessible areas in the
                old worlds.
                <b>Exploration is the key to the game</b>, and the player's curiosity is greatly rewarded.<br><br>

                I liked the Game and the Tech design more than 3D modeling! I took great pleasure in writing the atmospheres, the gameplay, the types
                of dungeons, the characters, the enemies... I wrote everyone's tasks for each area. I learned how to make blueprints and to talk to devs.
                Using <b>Gitlab</b>, <b>Trello</b>
                and <b>Discord</b>, I trained also on <b>Blender</b> and created the first characters and static entities of the game
                such as trees, benches, bushes.

                <br/><br/> The development of this first prototype, thus confirmed my professional ambition: Game Design and the desire
                to create games at the heart of the players' desires.


              </v-card-text>

              <v-row class="ml-3">
                <v-col cols="3">
                  <modal :image="CATree3_Front"/>
                </v-col>
                <v-col cols="3">
                  <modal :image="CATree3_Side"/>
                </v-col>
                <v-col cols="3">
                  <modal :image="CATree3"/>
                </v-col>
                <v-col cols="3" >
                  <v-hover>
                    <template v-slot:default="{ hover }">
                      <v-card
                          elevation="15"
                          class="mx-auto">
                        <v-img
                            v-show="hiddenTopRight"
                            width="180" height="300"
                            src="@/assets/LCAGameDesignPage.jpg">
                          <v-fade-transition>
                            <v-overlay
                                v-if="hover"
                                :opacity="0.8"
                                absolute
                                color="#1A1A1A"
                            >
                              <v-btn
                                  href="Cube Adventures, Game Design.pdf"
                                  class="ma-2"
                                  outlined
                                  download>
                                Game Design
                              </v-btn>
                            </v-overlay>
                          </v-fade-transition>
                        </v-img>
                      </v-card>

                    </template>
                  </v-hover>
                </v-col>

              </v-row>
            </v-col>
            <v-col cols="4" class="mt-n6">
              <v-row>
                <v-col cols="12">
                  <v-img
                      v-show="hiddenTopRight"
                      class="image--shadow mr-2"
                      src="@/assets/CAWalkingAnimation.gif"></v-img>

                </v-col>
                <v-col cols="12">
                  <v-img
                      v-show="hiddenTopRight"
                      class="image--shadow mr-2 mt-n2"
                      src="@/assets/CAChicken.gif"></v-img>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-card>


        <!--        <v-card-->
        <!--            color="primary"-->
        <!--            class="mt-8 myFont"-->
        <!--        >-->
        <!--          <v-card-title style=" word-break: break-word;"><h3 class="myFont mt-0">Bulk creations</h3></v-card-title>-->
        <!--          <br>-->
        <!--          <v-row-->
        <!--              v-if="this.$vuetify.breakpoint.width > 1000"-->
        <!--              class=" align-center mx-auto"-->
        <!--          >-->
        <!--            <v-col-->
        <!--                class="mx-auto"-->
        <!--                cols="4"-->
        <!--            >-->
        <!--              <modal :image="RetroPath"/>-->
        <!--            </v-col>-->
        <!--            <v-col-->
        <!--                class="mx-auto"-->
        <!--                cols="4"-->
        <!--            >-->
        <!--              <modalVideo :image="InsanePyramid"/>-->
        <!--            </v-col>-->
        <!--            <v-col-->
        <!--                class="mx-auto"-->
        <!--                cols="4"-->
        <!--            >-->
        <!--              <modal :image="RetroPath"/>-->
        <!--            </v-col>-->
        <!--          </v-row>-->
        <!--        </v-card>-->

      </v-card>
    </v-expand-x-transition>





    <!--    Mobile Version-->
    <v-expand-x-transition v-else>
      <v-card
          color="primary"
          v-show="hiddenTopRight"
          :max-width="this.$vuetify.breakpoint.width-20"
          min-height="400"
          :max-height="this.$vuetify.breakpoint.height-245"
          content="start"
          class="pb-3 mt-3 scroll pr-2 myFont"
          shaped
          style="
         background-color: rgba(255, 255, 255, 0) !important;
         position: fixed;top: 150px ;right: 10px;
         z-index: 4;
        "
      >
        <v-card
            color="primary"
            class="myFont"
        >
          <v-card-title style="justify-content: center; word-break: break-word;"><h2 class="myFont mt-0">Projects and Achievements</h2></v-card-title>
        </v-card>
        <!--  Shidôkan  -->
        <v-card
            color="primary"
            class="mt-5 myFont"
        >
          <v-card-title style=" word-break: break-word;">Shidôkan : The Labyrinth </v-card-title>
          <v-row>
            <!--            pictures              -->
            <v-col cols="12" class="ml-3">
              <v-row>
                <v-col cols="5">
                  <modal :image="GGJLogo"/>
                  <modal :image="GGJLequipe" class="mx-auto pt-5"/>
                </v-col>
                <v-col cols="7">

                  <v-row>
                    <v-col cols="12">
                      <modal :image="GGJSC1" class="mr-5"/>
                    </v-col>
                    <v-col cols="12">
                      <modalVideo :image="GameplayGGJ" class="mr-4 mt-4"/>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-col>
            <!--            Text              -->
            <v-col cols="12" class="text-center ">
              <v-card-text>
                January 2021, we started an adventure proposed by the <b>Global
                Game Jam</b>. Composed of a <b>team of 6 people</b>: three developers, a Game Artist,
                a Sound Designer and myself as Game Designer, we have <b>48 hours</b> to make a
                game around the theme <i>"Lost & Found"</i>. The machine is set in motion, after
                creating a context and a scenario, the project starts and we create a
                complete <b>Unity 2D mobile game.</b> <br><br><u>But how did we make a unique maze game?</u>
                We have created a game with <b>an evolving gameplay including shortcuts creation, weapon
                unlock, and in-game level changes</b>, random terrain
                generation, more than thirty unique sounds and 4 musics.<br>
                The overall atmosphere of the game becomes more and more stressful and threatening, which will have
                implied choices of colors and music that vary during the progression of the game.<br>
                In addition, bridging the gap between game design and development was very exciting.<br>
                Using digital tools such as <b>Trello</b>, <b>GitLab</b> and <b>Discord</b>, the game is fully
                developed with <b>Unity</b> in <b>C#</b> is finished 8 hours before the deadline. A rich,
                exciting and emotional experience!
                <v-row>
                  <v-col cols="12" class="text-center">
                    <br><a class="text--primary" target="_blank" href="https://globalgamejam.org/2021/games/shid%C3%B4kan-le-labyrinth-6">See the game on the GGJ website.</a>
                    <br><a class="text--primary" target="_blank" href="https://quentin-veillas.itch.io/shidokan-le-labyrinthe">Visit the Itch.io page of the game.</a>
                  </v-col>
                  <v-col cols="12" class="text-center">
                    <v-btn
                        href="Shidôkan_Le_Labyrinthe.apk"
                        class="ma-2 mt-6"
                        outlined
                        download>
                      Download the game's APK
                    </v-btn>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-col>

          </v-row>
        </v-card>

        <!--  Simulateur de Bifurcation Biométrique  -->
        <v-card
            color="primary"
            class="mt-8 myFont"
        >
          <v-card-title style=" word-break: break-word;">Biometric Bifurcation Simulator</v-card-title>
          <br>
          <v-row>
            <v-col cols="12" class="ml-2 mt-3 mr-4">
              <v-row>
                <v-col cols="5">
                  <modal :image="SBBSceno" class="mt-n2 ml-3 mr-n3"/>
                  <modal :image="SBBFinal" class="pt-5 mr-n5"/>
                </v-col>
                <v-col cols="7">

                  <v-row class="mt-n8">
                    <v-col cols="11" class="">
                      <modal :image="SBBGif" class=""/>
                    </v-col>
                    <v-col cols="11" class="ml-1 mt-n3">
                      <modal :image="SBBTableauDeBord"/>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" class="text-right">
              <v-card-text >
                <b><i>"How to create a museum simulation piece where several members of the public can interact and
                  evolve a population
                  of fantasy beings over half a day?"</i></b> <br> <br>
                <b> For 6 months</b>, a <b>team of 9 people</b>, including 4 students from <i>Design, Arts and Industry</i>
                and 5 engineers, worked hand in hand to create a <b>museum piece</b> for the Saint-Etienne Biennial.<br>

                <u>The design of the game has been well thought out and worked on:</u> the spectators must follow the evolution
                of a population of supernatural creatures following choices made. <b>When the participant sees the work
                for
                the first time, they see it upside down. Only one sentence is right-side up.
                The participant has to answer the question asked by passing to the left or the right of the work. Once
                the decision is made, the participant sees the work "the right way round" and sees the evolution of the
                population live, according to the chosen answer.</b>
                It is thus a simulation which counts the participation of about <b>fifteen participants</b> in each
                reboot, and about ten possible ends according to the choices of the participants. The project was
                realized by associating several technologies such as <b>Unreal Engine 4</b>, <b>Gitlab</b>, <b>Trello</b>,
                <b>Three.js</b>, <b>Blender</b> and <b>Discord</b>.
              </v-card-text>
            </v-col>
          </v-row>
        </v-card>

        <!--  Cube Adventures  -->
        <v-card
            color="primary"
            class="mt-8 myFont"
        >
          <v-card-title style=" word-break: break-word;">Cube Adventures - my first video game project</v-card-title>
          <v-row class="mt-2">
            <v-col cols="12" class="mt-n6">
              <v-row>
                <v-col cols="1"/>
                <v-col cols="5">
                  <modal :image="WalkinMan"/>
                </v-col>
                <v-col cols="5">
                  <modal :image="Chicken"/>
                </v-col>
                <v-col cols="1"/>
              </v-row>
            </v-col>
          </v-row>
          <v-row class="mt-2">
            <v-row class="align-content-center mr-1" >

              <v-col cols="1"/>
              <v-col cols="5" class="mt-12 mb-8">
                <modal :image="CATree3"/>
              </v-col>
              <v-col cols="6" class="ml-n2 ">
                <v-hover class="mx-auto">
                  <template v-slot:default="{ hover }">
                    <v-img
                        class="image--shadow"
                        v-show="hiddenTopRight"
                        :width="$vuetify.breakpoint.width*0.4"
                        src="@/assets/LCAGameDesignPage.jpg">
                      <v-fade-transition>
                        <v-overlay
                            v-if="hover"
                            :opacity="0.8"
                            absolute
                            color="#1A1A1A"
                        >
                          <v-btn
                              href="Cube Adventures, Game Design.pdf"
                              class="ma-2"
                              outlined
                              width="130"
                              download>
                            Game Design
                          </v-btn>
                        </v-overlay>
                      </v-fade-transition>
                    </v-img>

                  </template>
                </v-hover>
              </v-col>

              <v-col cols="1"/>
              <v-col cols="5" class="mt-n10">
                <modal :image="CATree3_Front"/>
              </v-col>
              <v-col cols="5">
                <modal :image="CATree3_Side"/>
              </v-col>
              <v-col cols="1"/>

            </v-row>

            <v-col cols="12" class="text-left">
              <v-card-text>
                At the end of 2020, an idea came into my head: <b><i>"what if I used my free time to create my own video game?"</i></b><br>
                So I started with a developer friend on <b>Unity</b> and another Sound Designer friend, my role was to be the
                <b>Game Designer</b>, but also the <b>3D Game Artist</b>. <br>The scenario is written and we start on a <b>3D
                RPG </b> in the style of Cube World, but revisited with several worlds accessible, several themes and atmospheres.
                The player explores an open world where he can discover villages, wild enemies, and dungeons.
                <br><br>
                <u>Game and Level Design:</u> <b>The player explores</b> an <b>open world</b> where he can discover
                villages, wild enemies, and dungeons.
                Within these dungeons are series of enemies and a final boss with a unique power. <b>When the boss is defeated,
                a teleportation portal to a new world appears</b>, and <b>the player gains the power of this boss that
                will
                allow him/her
                to survive in this new world</b> (e.g. the power to fly in an aerial world or to breathe indefinitely in a
                water world).
                These new powers can also allow, in the manner of a <i>Metroidvania</i>, to access inaccessible areas in the
                old worlds.
                <b>Exploration is the key to the game</b>, and the player's curiosity is greatly rewarded.<br><br>

                I liked the Game design more than 3D modeling! I took great pleasure in writing the atmospheres, the gameplay, the types
                of dungeons, the characters, the enemies... I wrote everyone's tasks for each area. I learned how to make blueprints and to talk to devs.
                Using <b>Gitlab</b>, <b>Trello</b> and <b>Discord</b>, I trained also on <b>Blender</b>
                and created the first characters and static entities of the game such as trees, benches, bushes.

                <br/><br/> The development of this first prototype, thus confirmed my professional ambition: Game Design and the desire
                to create games at the heart of the players' desires.
              </v-card-text>
            </v-col>
          </v-row>
        </v-card>
      </v-card>
    </v-expand-x-transition>



    <v-card color="#030216"
            width="116"
            height="45 "
            style="position: fixed;bottom: 5px ;right: 6px;">
    </v-card>
  </div>
</template>

<script>
import Modal from "@/components/Modal";
import ModalVideo from "@/components/ModalVideo";

export default {
  name: 'HelloWorld',
  components:{
    Modal,
    ModalVideo,
  },
  props: {
    msg: String,
  },
  data: () => ({
    overlay: false,
    height: 50,
    hiddenTopLeft: false,
    hiddenTopRight: false,
    hiddenBottomLeft: false,
    image: {
      url: require("@/assets/Quentin Veillas.jpg"),
      lazy_url: require("@/assets/LQuentin Veillas.jpg"),
      name: "",
    },
    GGJLogo:{
      url: require("@/assets/GGJLogo.jpg"),
      lazy_url: require("@/assets/LGGJLogo.jpg"),
      name: "",
    },
    GGJLequipe:{
      url: require("@/assets/GGJLequipe.jpg"),
      lazy_url: require("@/assets/LGGJLequipe.jpg"),
      name: "",
    },
    GGJSC1:{
      url: require("@/assets/GGJSC1.jpg"),
      lazy_url: require("@/assets/LGGJSC1.jpg"),
      name: "",
    },
    GameplayGGJ:{
      url: require("@/assets/GameplayGGJ.mp4"),
      lazy_url: require("@/assets/LGameplayGGJ.mp4"),
      name: "",
    },
    SBBFinal:{
      url: require("@/assets/SBBFinal.png"),
      lazy_url: require("@/assets/LSBBFinal.png"),
      name: "",
    },
    SBBGif:{
      url: require("@/assets/SBBGif.gif"),
      lazy_url: require("@/assets/LSBBGif.gif"),
      name: "",
    },
    SBBSceno:{
      url: require("@/assets/SBBSceno.png"),
      lazy_url: require("@/assets/LSBBSceno.png"),
      name: "",
    },
    SBBTableauDeBord:{
      url: require("@/assets/SBBTableauDeBord.jpg"),
      lazy_url: require("@/assets/LSBBTableauDeBord.jpg"),
      name: "",
    },
    CATree3:{
      url: require("@/assets/CATree3.jpg"),
      lazy_url: require("@/assets/LCATree3.jpg"),
      name: "",
    },
    CATree3_Front:{
      url: require("@/assets/CATree3_Front.png"),
      lazy_url: require("@/assets/LCATree3_Front.png"),
      name: "",
    },
    CATree3_Side:{
      url: require("@/assets/CATree3_Side.png"),
      lazy_url: require("@/assets/LCATree3_Side.png"),
      name: "",
    },
    CAGameDesignPage:{
      url: require("@/assets/CAGameDesignPage.jpg"),
      lazy_url: require("@/assets/LCAGameDesignPage.jpg"),
      name: "",
    },
    WalkinMan:{
      url: require("@/assets/CAWalkingAnimation.gif"),
      lazy_url: require("@/assets/CAWalkingAnimation.gif"),
      name: "",
    },
    Chicken:{
      url: require("@/assets/CAChicken.gif"),
      lazy_url: require("@/assets/CAChicken.gif"),
      name: "",
    },
    RetroPath:{
      url: require("@/assets/RetroPath.gif"),
      lazy_url: require("@/assets/LRetroPath.gif"),
      name: "",
    },
    InsanePyramid:{
      url: require("@/assets/InsanePyramid.mp4"),
      lazy_url: require("@/assets/LInsanePyramid.mp4"),
      name: "",
    },



  }),
  methods: {
    async hiddenTopRightMethod () {
      if (this.hiddenTopLeft == true){
        this.hiddenTopLeft = false;
      }
      if (this.hiddenBottomLeft == true){
        this.hiddenBottomLeft = false;
      }
      this.hiddenTopRight = !this.hiddenTopRight;
    },
    async hiddenTopLeftMethod () {
      if (this.hiddenTopRight == true){
        this.hiddenTopRight = false;
      }
      if (this.hiddenBottomLeft == true){
        this.hiddenBottomLeft = false;
      }
      this.hiddenTopLeft = !this.hiddenTopLeft;
    },
    async hiddenBottomLeftMethod () {
      if (this.hiddenTopRight == true){
        this.hiddenTopRight = false;
      }
      if (this.hiddenTopLeft == true){
        this.hiddenTopLeft = false;
      }
      this.hiddenBottomLeft = !this.hiddenBottomLeft;
    },
    async resetMethod () {
      if (this.hiddenTopRight == true){
        this.hiddenTopRight = false;
      }
      if (this.hiddenTopLeft == true){
        this.hiddenTopLeft = false;
      }
      if (this.hiddenBottomLeft == true){
        this.hiddenBottomLeft = false;
      }
    },
    async Close(){
      this.hiddenTopRight = false;
      this.hiddenTopLeft = false;
      this.hiddenBottomLeft = false;
    }
  },

}

</script>



<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

.v-image.image--shadow {
  box-shadow: 0 6px 6px -3px rgba(0,0,0,.2),0 10px 14px 1px rgba(0,0,0,.14),0 4px 18px 3px rgba(0,0,0,.12)!important;
}

.lang{
  cursor : pointer;
}

.backG {
  position: fixed;
  background: #000;
  border: none;
  top: 0; right: 0;
  bottom: 0; left: 0;
  width: 100%;
  height: 100%;
}

.scroll {
  overflow-y: auto;
  overflow-x: hidden;
}

h1  {
  font-family: "Roboto", sans-serif;
}
h2  {
  font-family: "Roboto", sans-serif ;
}
h3 {
  font-family: "Roboto", sans-serif;
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #A8E6CE;
}

/* Scroll 5 */

::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}
::-webkit-scrollbar-track {
  border-radius: 7px;
  background-color: #030216 ;
}
::-webkit-scrollbar-thumb {
  background-image: linear-gradient(45deg, #AF54FB, #AF54FB);
  border-radius: 7px;
  -webkit-box-shadow: #030216;
}

.no-background-hover::before {
  background-color: transparent !important;
}


.myFont{
  font-family: "Futura md BT" ;
}

</style>

<template onclick="this.resetMethod()">

  <div class="hello">

    <!--    Background    -->
    <iframe class="backG" v-if="this.$vuetify.breakpoint.height>820" src='https://my.spline.design/dany-5c65bffe981d4e7ee6d8e656cf569c09/' frameborder='0' width='100%' height='100%'></iframe>
    <iframe class="backG" v-if="this.$vuetify.breakpoint.height<821" src='https://my.spline.design/danycopy-db328c8a05109def1aaf102d52b2bdef/' frameborder='0' width='100%' height='100%'></iframe>
    <!--    &lt;!&ndash;    Menu     &ndash;&gt;-->
    <!--    <v-card class="circle mx-auto"> Click me</v-card>-->
    <!--    <v-card class="circle mx-auto" id="hiddenCircle"> Click me</v-card>-->


    <v-card class="myfont text--secondary text-right transparent" width="100" style="position: absolute; right: 3px; top:-105px;z-index: 15; box-shadow: none !important;">
      <v-card-text style="box-shadow: none !important;">
        <span color="#ffffff" @click="$router.replace('/')" style="font-size: 17px;" class="lang white--text "> <b>FR</b> </span>
        <span color="#ffffff" style="font-size: 23px;" class="white--text"> |</span>
        <span color="#ffffff" @click="$router.replace('/en')" style="font-size: 16px;" class="lang white--text "> EN</span>
      </v-card-text>
    </v-card>
    <!--    videos   -->
    <v-container v-if="this.$vuetify.breakpoint.height>820" >
      <v-spacer/>
      <v-btn
          :ripple="false"
          rounded
          color="#821FC5"
          height="55"
          width="170"
          large
          @click="hiddenTopLeftMethod"
          class="mt-5 secondary--text transparent no-background-hover"
          style="position: fixed;top: 85px;margin-left: -220px;"
      >
        <video id="vid1"  src="@/assets/Cube.mp4" type="video/mp4" autoplay loop muted playsinline></video>
      </v-btn>
      <v-spacer/>
      <v-btn
          :ripple="false"
          rounded
          color="#821FC5"
          height="55"
          width="153"
          @click="hiddenTopRightMethod"
          class="mt-5  secondary--text transparent no-background-hover"
          large
          style="position: fixed;top: 85px;margin-left: 80px"
      >
        <video id="vid2"  src="@/assets/Icosahedron.mp4" type="video/mp4" autoplay loop muted playsinline></video>
      </v-btn>
      <v-spacer/>
    </v-container>

    <!--   videos   mobile   -->
    <v-container v-else >
      <v-spacer/>
      <v-btn
          :ripple="false"
          rounded
          color="#821FC5"
          height="55"
          width="170"
          large
          @click="hiddenTopLeftMethod"
          class="mt-5 secondary--text transparent no-background-hover"
          style="position: fixed;top: 85px;margin-left: -170px;"
      >
        <video id="vid1m"  src="@/assets/Cube.mp4" type="video/mp4" autoplay loop muted playsinline></video>
      </v-btn>
      <v-spacer/>
      <v-btn
          :ripple="false"
          rounded
          color="#821FC5"
          height="55"
          width="153"
          @click="hiddenTopRightMethod"
          class="mt-5  secondary--text transparent no-background-hover"
          large
          style="position: fixed;top: 85px;margin-left: 10px"
      >
        <video id="vid2m"  src="@/assets/Icosahedron.mp4" type="video/mp4" autoplay loop muted playsinline></video>
      </v-btn>
      <v-spacer/>
    </v-container>


    <!--    Qui suis-je ?    -->
    <v-expand-transition>
      <v-card
          color="primary"
          v-show="hiddenTopLeft"
          min-width="300"
          max-width="600"
          :max-height="this.$vuetify.breakpoint.height-170"
          content="start"
          class="pb-3 mt-3 mr-2 scroll myFont "
          style="
         opacity: 0.95;
         position: fixed;top: 150px ;left: 10px;
         z-index: 4;
        "
      >
        <v-card-title style="display: block; word-break: break-word;"><h2 class="myFont">Qui suis-je ? <br>Quentin Veillas</h2></v-card-title>
        <v-card-text >
          Un <b>passioné du jeux-vidéo</b> armé d'une <b>motivation débordante</b>, d'une <b>envie
          de bien faire</b> et d'une<b> capacité d'apprentissage rapide</b>.
          Ces éléments, à travers mes formations d'<b>ingénieur</b> à <b>Télécom Saint-Étienne
        </b> (avec des travaux partagés avec des élèves de Design, Métiers d'Art et d'Industrie) et
          <b>MEDIA </b>(<b>Management de projets digitaux & media</b>) à <b>Télécom SudParis</b> et
          <b>Institut Mines-Télécom Business School</b>, m'ont permis de développer mes compétences en
          <b>ingénierie, management et design</b>.
        </v-card-text>
        <v-card-text>
          Durant mes années d'études, j'ai été Responsable Gaming au sein de Télécom Saint-Étienne, ce qui
          m'a permis d'<b>organiser des événements e-sport</b> durant une année et de <b>me rendre compte
          des attentes des joueurs et d'effectuer une veille sur le marché du jeu vidéo</b>.
          La Global Game Jam 2021 m'a ouvert les yeux : le <b>Game Design</b> est fait pour moi.
        </v-card-text>
        <v-card-text>
          <b>Designer, développeur, ambitieux et artiste</b> : c'est ainsi que je me décris, et pourquoi
          le métier de <b>Game Designer Designer</b> me correspond.
        </v-card-text>

        <v-row v-if="this.$vuetify.breakpoint.width>500">
          <v-col cols="6" >
            <v-hover>
              <template v-slot:default="{ hover }">
                <v-card
                    elevation="15"
                    min-width="212"
                    max-width="212"
                    min-height="300"
                    max-height="300"
                    class="mx-auto">
                  <v-img
                      v-show="hiddenTopLeft"
                      width="212" height="300"
                      src="@/assets/LFrench CV Quentin VEILLAS.jpg">
                    <v-fade-transition>
                      <v-overlay
                          v-if="hover"
                          :opacity="0.8"
                          absolute
                          color="#1A1A1A"
                      >
                        <v-btn
                            href="French_CV_Quentin_VEILLAS.pdf"
                            class="ma-2"
                            outlined
                            download>
                          Télécharger le CV
                        </v-btn>
                      </v-overlay>
                    </v-fade-transition>
                  </v-img>
                </v-card>

              </template>
            </v-hover>
          </v-col>

          <v-col cols="6">
            <Modal :image="image" style="width: 212px; height: 300px;" class="mx-auto"/>
          </v-col>
        </v-row>


        <!--    Mobile    -->
        <v-row v-else>
          <v-col cols="6" >
            <v-hover>
              <template v-slot:default="{ hover }">
                <v-card
                    elevation="15"
                    min-width="160"
                    max-width="160"
                    min-height="250"
                    max-height="250"
                    class="mx-auto ml-2">
                  <v-img
                      v-show="hiddenTopLeft"
                      width="160" height="250"
                      src="@/assets/LFrench CV Quentin VEILLAS.jpg">
                    <v-fade-transition>
                      <v-overlay
                          v-if="hover"
                          :opacity="0.8"
                          absolute
                          color="#1A1A1A"
                      >
                        <v-btn
                            href="French_CV_Quentin_VEILLAS.pdf"
                            class="ma-4"
                            outlined
                            dense
                            width="150"
                            small
                            download>
                          Téléchargez le CV
                        </v-btn>
                      </v-overlay>
                    </v-fade-transition>
                  </v-img>
                </v-card>

              </template>
            </v-hover>
          </v-col>

          <v-col cols="6"  v-if="this.$vuetify.breakpoint.width>500">
            <Modal :image="image" style="width: 212px; height: 300px;" class="mx-auto"/>
          </v-col>
          <v-col cols="6"  v-else>
            <Modal :image="image" style="width: 140px; height: 250px;" class="mx-auto"/>
          </v-col>
        </v-row>
      </v-card>
    </v-expand-transition>


    <!--    Mes projets    -->
    <v-expand-x-transition v-if="(this.$vuetify.breakpoint.height/this.$vuetify.breakpoint.width)<1">
      <v-card
          color="primary"
          v-show="hiddenTopRight"
          max-width="1200"
          min-height="400"
          :max-height="this.$vuetify.breakpoint.height-245"
          content="start"
          class="pb-3 mt-3 ml-2 scroll pr-2"
          shaped
          style="
         background-color: rgba(255, 255, 255, 0) !important;
         position: fixed;top: 150px ;right: 10px;
         z-index: 4;
        "
      >
        <v-card
            color="primary"
            class="myFont"
            :width="this.$vuetify.breakpoint.width*0.4"
        >
          <v-card-title style="justify-content: center; word-break: break-word;"><h2 class="myFont mt-0">Projets et Réalisations</h2></v-card-title>
        </v-card>

        <v-card
            color="primary"
            class="mt-5 myFont"
        >
          <v-card-title style="word-break: break-word;"><h3 class="myFont mt-0">Shidôkan : Le Labyrinthe</h3></v-card-title>
          <v-row align="center"
                 justify="center">
            <v-col cols="6" class="text-left">
              <v-card-text>
                Janvier 2021, nous nous lançons dans l'aventure proposée par la <b>Global Game Jam</b>. Composé d'<b>une équipe 6 personnes</b>: trois dévelopeurs, un Game Artist, un Sound Designer et de moi-même en tant que Game Designer,
                nous avons <b>48 heures</b> pour réaliser un jeu autour du thème <i>"Lost & Found"</i>. La machine est enclenchée, après avoir créé un contexte et un scénario,
                le projet se lance et nous créons un <b>jeu mobile 2D</b> complet comprenant de l'évolution de gameplay, de la génération de terrain aléatoire,
                plus d'une trentaine de sons uniques et 4 musiques. <br>
                <br><u>Mais comment avons-nous fait pour créer un jeu de labyrinthe unique ?</u> Nous avons créé un jeu avec un <b>gameplay évolutif comprenant la création de raccourcis, le déblocage d'armes, et des changements de niveau dans le jeu en live</b>, la génération aléatoire de terrain, plus de trente sons uniques et 4 musiques.<br>
                L'atmosphère générale du jeu devient de plus en plus stressante et menaçante, ce qui implique des choix de couleurs et de musiques qui tendent vers des tons froids au cours de la progression du jeu.<br>
                De plus, combler le fossé entre le Game Design et le développement du jeu à travers des la communication personnalisée entre les membres de l'équipe était particulièrement excitant et satisfaisant !<br>
                <br>À l'aide d'outils numériques tels que <b>Trello</b>, <b>Gitlab</b> et <b>Discord</b>, le jeu développé sur <b>Unity</b> en <b>C#</b> est terminé 8h avant la deadline, il ne restait alors que les corrections de bugs,
                et la présentation.
                Une expérience riche, passionante et pleine d'émotion !<br>

                <v-row>
                  <v-col cols="6">
                    <br><a class="text--primary" target="_blank" href="https://globalgamejam.org/2021/games/shid%C3%B4kan-le-labyrinth-6">Voir le jeu sur le site de la GGJ.</a>
                    <br><a class="text--primary" target="_blank" href="https://quentin-veillas.itch.io/shidokan-le-labyrinthe">Visiter la page Itch.io du jeu.</a>
                  </v-col>
                  <v-col cols="5">
                    <v-btn
                        href="Shidôkan_Le_Labyrinthe.apk"
                        class="ma-2 mt-6 ml-n5"
                        outlined
                        download>
                      Téléchargez l'APK du jeu
                    </v-btn>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-col>
            <v-col cols="6" class="mt-n6">
              <v-row>
                <v-col cols="5">
                  <modal :image="GGJLogo"/>
                </v-col>
                <v-col cols="6">
                  <modal :image="GGJSC1" class="mr-5 mt-8"/>
                </v-col>
                <v-col cols="6">
                  <modal :image="GGJLequipe" class="mx-auto pt-5"/>
                </v-col>
                <v-col cols="5" class="pt-n5">
                  <modalVideo :image="GameplayGGJ" class="pt-5 mt-n5"/>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-card>

        <v-card
            color="primary"
            class="mt-8 myFont"
        >
          <v-card-title style="word-break: break-word;"><h3 class="myFont mt-0">Simulateur de Bifurcation Biométrique</h3></v-card-title>
          <br>
          <v-row>
            <v-col cols="6" class="ml-5">
              <v-row>
                <v-col cols="5">
                  <modal :image="SBBSceno"/>
                  <modal :image="SBBFinal" class="pt-5 mr-n5" style="width: 300px"/>
                </v-col>
                <v-col cols="7">

                  <v-row class="mt-n8">
                    <v-col cols="12">
                      <modal :image="SBBGif" class="ml-n5" style="width: 350px"/>
                    </v-col>
                    <v-col cols="8" class="mx-auto">
                      <modal :image="SBBTableauDeBord" />
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="" class="text-right mt-n13">
              <v-card-text >
                <i><b>"Comment créer une pièce artistique de simulation où plusieurs membres du public peuvent interagir et faire évoluer une population d'êtres fantastiques sur une demi-journée ?".</b><br><br></i>
                <b>Pendant 6 mois,</b>, une <b>équipe de 9 personnes</b> dont 4 étudiants de <i>Design, Métiers d'Art et d'Industrie</i> et 5 ingénieurs travaillent main dans la main pour réaliser une
                <b>œuvre muséale</b> à l'occasion de la Biennalle de Saint-Étienne.<br>
                <br><u>Le Design du Jeu a été très réfléchi et travaillé :</u> les spectateurs doivent suivre l'évolution d'une population de créatures surnaturelles suite à des choix effectués. Cependant, il faut s'adapter aux restrictions du COVID, et nous voulons susciter des sens inhabituellements utilisés dans des simulations : <b>lorsque le participant voit l'œuvre pour la première fois, il la voit à l'envers.</b> Seule une phrase est à l'endroit. <b>Le participant doit répondre à la question posée en passant à gauche ou à droite de l'œuvre</b>. Une fois la décision prise,<b> le participant voit cette fois-ci l'œuvre "à l'endroit"</b> et voit l'évolution de la population en direct, en fonction de la réponse choisie.
                <br><br>Il s'agit donc d'une simulation qui compte <b>la participation d'une quinzaine de personnes</b> à chaque redémarrage, et une dizaine de fins possibles en fonction des choix des participants. Le projet a été réalisé en associant plusieurs technologies telles que <b>Unreal Engine 4</b>, <b>Gitlab</b>, <b>Trello</b>, <b>Three.js</b>, <b>Blender</b> et <b>Discord</b>.
              </v-card-text>
            </v-col>
          </v-row>
        </v-card>
        <v-card
            color="primary"
            class="mt-8 myFont"
        >
          <v-card-title style="word-break: break-word;"><h3 class="myFont mt-0">Cube Adventures - mon premier projet jeu-vidéo</h3></v-card-title>
          <v-row>
            <v-col cols="8" class="text-left">
              <v-card-text>
                Fin 2020, une idée m'est venue en tête : <b><i>"et si j'utilisais mon temps libre pour créer mon propre jeu-vidéo ?"</i></b><br>
                Nous nous lançons avec deux amis Développeur et Sound Designer sur <b>Unity</b>. Mes rôles : le <b>Game Designer</b>,
                et le <b>Game Artist 3D</b>. <br>
                Le scénario est écrit et nous partons sur un <b>RPG 3D</b> dans le style de <i>Cube World</i>, mais revisité avec plusieurs mondes accessibles, plusieurs thèmes aux couleurs pastel et atmosphères. Le joueur explore un monde ouvert où il peut découvrir des villages, des ennemis sauvages, et des donjons.
                <br> <br>

                <u>Game et Level Design :</u> <b>Le joueur explore</b> un <b>monde ouvert</b> où il peut découvrir des villages, des ennemis sauvages et des donjons. Dans ces donjons se trouvent des séries d'ennemis et un boss final doté d'un pouvoir unique. <b>Lorsque le boss est vaincu, un portail de téléportation vers un nouveau monde apparaît</b>,<b> et le joueur gagne le pouvoir de ce boss qui lui permettra de survivre dans ce nouveau monde </b>(par exemple, le pouvoir de voler dans un monde aérien ou de respirer indéfiniment dans un monde aquatique). Ces nouveaux pouvoirs peuvent également permettre, à la manière d'un Metroidvania, d'accéder à des zones inaccessibles dans les anciens mondes. <b>L'exploration est la clé du jeu</b>, et la <b>curiosité du joueur</b> est largement récompensée.
                De plus, ces pouvoirs serviront également à atteindre de nouveaux lieux, trésors et quêtes secondaires désormais accessibles sur les anciens mondes, à la manière d'un <b>Metroid-Vania</b>.

                <br><br>J'ai préféré le Game Design à la modélisation 3D ! J'ai pris beaucoup de plaisir à écrire les atmosphères, le gameplay, les types de donjons, les personnages, les ennemis... J'ai écrit les tâches de chacun pour chaque zone. J'ai appris à faire des plans et à parler aux développeurs. En utilisant <b>Gitlab</b>, <b>Trello</b> et <b>Discord</b>, je me suis également formé à <b>Blender</b> et j'ai créé les premiers personnages et entités statiques du jeu tels que des arbres, des bancs, des buissons.

                <br><br>Le développement de ce premier prototype, a ainsi confirmé mon ambition professionnelle : Le Game Design et <u>l'envie de créer des jeux au cœur des envies des joueurs</u>.


              </v-card-text>

              <v-row class="ml-3">
                <v-col cols="3">
                  <modal :image="CATree3_Front"/>
                </v-col>
                <v-col cols="3">
                  <modal :image="CATree3_Side"/>
                </v-col>
                <v-col cols="3">
                  <modal :image="CATree3"/>
                </v-col>
                <v-col cols="3" >
                  <v-hover>
                    <template v-slot:default="{ hover }">
                      <v-card
                          elevation="15"
                          class="mx-auto">
                        <v-img
                            v-show="hiddenTopRight"
                            width="180" height="300"
                            src="@/assets/LCAGameDesignPage.jpg">
                          <v-fade-transition>
                            <v-overlay
                                v-if="hover"
                                :opacity="0.8"
                                absolute
                                color="#1A1A1A"
                            >
                              <v-btn
                                  href="Cube Adventures, Game Design.pdf"
                                  class="ma-2"
                                  outlined
                                  download>
                                Game Design
                              </v-btn>
                            </v-overlay>
                          </v-fade-transition>
                        </v-img>
                      </v-card>

                    </template>
                  </v-hover>
                </v-col>

              </v-row>
            </v-col>
            <v-col cols="4" class="mt-n6">
              <v-row>
                <v-col cols="12">
                  <v-img
                      v-show="hiddenTopRight"
                      class="image--shadow mr-2"
                      src="@/assets/CAWalkingAnimation.gif"></v-img>

                </v-col>
                <v-col cols="12">
                  <v-img
                      v-show="hiddenTopRight"
                      class="image--shadow mr-2 mt-n2"
                      src="@/assets/CAChicken.gif"></v-img>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-card>
      </v-card>
    </v-expand-x-transition>

    <!--    Mobile Version   -->
    <v-expand-x-transition v-else>
      <v-card
          color="primary"
          v-show="hiddenTopRight"
          :max-width="this.$vuetify.breakpoint.width-20"
          min-height="400"
          :max-height="this.$vuetify.breakpoint.height-245"
          content="start"
          class="pb-3 mt-3 scroll pr-2 myFont"
          shaped
          style="
         background-color: rgba(255, 255, 255, 0) !important;
         position: fixed;top: 150px ;right: 10px;
         z-index: 4;
        "
      >
        <v-card
            color="primary"
            class="myFont"
        >
          <v-card-title style="justify-content: center; word-break: break-word;"><h2 class="myFont mt-0">Mes Projets et Réalisations</h2></v-card-title>
        </v-card>
        <!--  Shidôkan  -->
        <v-card
            color="primary"
            class="mt-5 myFont"
        >
          <v-card-title style="word-break: break-word;">Shidôkan : Le Labyrinthe </v-card-title>
          <v-row>
            <!--            pictures              -->
            <v-col cols="12" class="ml-3 mt-3">
              <v-row>
                <v-col cols="5">
                  <modal :image="GGJLogo"/>
                  <modal :image="GGJLequipe" class="mx-auto pt-5"/>
                </v-col>
                <v-col cols="7">

                  <v-row>
                    <v-col cols="12">
                      <modal :image="GGJSC1" class="mr-5"/>
                    </v-col>
                    <v-col cols="12">
                      <modalVideo :image="GameplayGGJ" class="mr-4 mt-4"/>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-col>
            <!--            Text              -->
            <v-col cols="12" class="text-center">
              <v-card-text>
                Janvier 2021, nous nous lançons dans l'aventure proposée par la <b>Global Game Jam</b>. Composé d'<b>une équipe 6 personnes</b>: trois dévelopeurs, un Game Artist, un Sound Designer et de moi-même en tant que Game Designer,
                nous avons <b>48 heures</b> pour réaliser un jeu autour du thème <i>"Lost & Found"</i>. La machine est enclenchée, après avoir créé un contexte et un scénario,
                le projet se lance et nous créons un <b>jeu mobile 2D</b> complet comprenant de l'évolution de gameplay, de la génération de terrain aléatoire,
                plus d'une trentaine de sons uniques et 4 musiques. <br>
                <br><u>Mais comment avons-nous fait pour créer un jeu de labyrinthe unique ?</u> Nous avons créé un jeu avec un <b>gameplay évolutif comprenant la création de raccourcis, le déblocage d'armes, et des changements de niveau dans le jeu en live</b>, la génération aléatoire de terrain, plus de trente sons uniques et 4 musiques.<br>
                L'atmosphère générale du jeu devient de plus en plus stressante et menaçante, ce qui implique des choix de couleurs et de musiques qui tendent vers des tons froids au cours de la progression du jeu.<br>
                De plus, combler le fossé entre le Game Design et le développement du jeu à travers des la communication personnalisée entre les membres de l'équipe était particulièrement excitant et satisfaisant !<br>
                <br>À l'aide d'outils numériques tels que <b>Trello</b>, <b>Gitlab</b> et <b>Discord</b>, le jeu développé sur <b>Unity</b> en <b>C#</b> est terminé 8h avant la deadline, il ne restait alors que les corrections de bugs,
                et la présentation.
                Une expérience riche, passionante et pleine d'émotion !<br>

                <v-row>
                  <v-col cols="12" class="text-center">
                    <br><a class="text--primary" target="_blank" href="https://globalgamejam.org/2021/games/shid%C3%B4kan-le-labyrinth-6">Voir le jeu sur le site de la GGJ.</a>
                    <br><a class="text--primary" target="_blank" href="https://quentin-veillas.itch.io/shidokan-le-labyrinthe">Visiter la page Itch.io du jeu.</a>
                  </v-col>
                  <v-col cols="12" class="text-center">
                    <v-btn
                        href="Shidôkan_Le_Labyrinthe.apk"
                        class="ma-2 mt-6"
                        outlined
                        download>
                      Téléchargez l'APK du jeu
                    </v-btn>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-col>

          </v-row>
        </v-card>

        <!--  Simulateur de Bifurcation Biométrique  -->
        <v-card
            color="primary"
            class="mt-8 myFont"
        >
          <v-card-title style="word-break: break-word;">Simulateur de Bifurcation Biométrique</v-card-title>
          <br>
          <v-row>
            <v-col cols="12" class="ml-2 mt-3 mr-4">
              <v-row>
                <v-col cols="5">
                  <modal :image="SBBSceno" class="mt-n2 ml-3 mr-n3"/>
                  <modal :image="SBBFinal" class="pt-5 mr-n5"/>
                </v-col>
                <v-col cols="7">

                  <v-row class="mt-n8">
                    <v-col cols="11" class="">
                      <modal :image="SBBGif" class=""/>
                    </v-col>
                    <v-col cols="11" class="ml-1 mt-n3">
                      <modal :image="SBBTableauDeBord"/>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" class="text-right">
              <v-card-text >
                <i><b>"Comment créer une pièce artistique de simulation où plusieurs membres du public peuvent interagir et faire évoluer une population d'êtres fantastiques sur une demi-journée ?".</b><br><br></i>
                <b>Pendant 6 mois,</b>, une <b>équipe de 9 personnes</b> dont 4 étudiants de <i>Design, Métiers d'Art et d'Industrie</i> et 5 ingénieurs travaillent main dans la main pour réaliser une
                <b>œuvre muséale</b> à l'occasion de la Biennalle de Saint-Étienne.<br>
                <br><u>Le Design du Jeu a été très réfléchi et travaillé :</u> les spectateurs doivent suivre l'évolution d'une population de créatures surnaturelles suite à des choix effectués. Cependant, il faut s'adapter aux restrictions du COVID, et nous voulons susciter des sens inhabituellements utilisés dans des simulations : <b>lorsque le participant voit l'œuvre pour la première fois, il la voit à l'envers.</b> Seule une phrase est à l'endroit. <b>Le participant doit répondre à la question posée en passant à gauche ou à droite de l'œuvre</b>. Une fois la décision prise,<b> le participant voit cette fois-ci l'œuvre "à l'endroit"</b> et voit l'évolution de la population en direct, en fonction de la réponse choisie.
                <br><br>Il s'agit donc d'une simulation qui compte <b>la participation d'une quinzaine de personnes</b> à chaque redémarrage, et une dizaine de fins possibles en fonction des choix des participants. Le projet a été réalisé en associant plusieurs technologies telles que <b>Unreal Engine 4</b>, <b>Gitlab</b>, <b>Trello</b>, <b>Three.js</b>, <b>Blender</b> et <b>Discord</b>.
              </v-card-text>
            </v-col>
          </v-row>
        </v-card>

        <!--  Cube Adventures  -->
        <v-card
            color="primary"
            class="mt-8 myFont"
        >
          <v-card-title style="word-break: break-word;">Cube Adventures - mon premier projet jeu-vidéo</v-card-title>
          <v-row class="mt-2">
            <v-col cols="12" class="mt-n6">
              <v-row>
                <v-col cols="1"/>
                <v-col cols="5">
                  <modal :image="WalkinMan"/>
                </v-col>
                <v-col cols="5">
                  <modal :image="Chicken"/>
                </v-col>
                <v-col cols="1"/>
              </v-row>
            </v-col>
          </v-row>
          <v-row class="mt-2">
            <v-row class="align-content-center mr-1" >

              <v-col cols="1"/>
              <v-col cols="5" class="mt-12 mb-8">
                <modal :image="CATree3"/>
              </v-col>
              <v-col cols="6" class="ml-n2 ">
                <v-hover class="mx-auto">
                  <template v-slot:default="{ hover }">
                    <v-img
                        class="image--shadow"
                        v-show="hiddenTopRight"
                        :width="$vuetify.breakpoint.width*0.4"
                        src="@/assets/LCAGameDesignPage.jpg">
                      <v-fade-transition>
                        <v-overlay
                            v-if="hover"
                            :opacity="0.8"
                            absolute
                            color="#1A1A1A"
                        >
                          <v-btn
                              href="Cube Adventures, Game Design.pdf"
                              class="ma-2"
                              outlined
                              width="130"
                              download>
                            Game Design
                          </v-btn>
                        </v-overlay>
                      </v-fade-transition>
                    </v-img>

                  </template>
                </v-hover>
              </v-col>

              <v-col cols="1"/>
              <v-col cols="5" class="mt-n10">
                <modal :image="CATree3_Front"/>
              </v-col>
              <v-col cols="5">
                <modal :image="CATree3_Side"/>
              </v-col>
              <v-col cols="1"/>

            </v-row>

            <v-col cols="12" class="text-left">
              <v-card-text>
                Fin 2020, une idée m'est venue en tête : <b><i>"et si j'utilisais mon temps libre pour créer mon propre jeu-vidéo ?"</i></b><br>
                Nous nous lançons avec deux amis Développeur et Sound Designer sur <b>Unity</b>. Mes rôles : le <b>Game Designer</b>,
                et le <b>Game Artist 3D</b>. <br>
                Le scénario est écrit et nous partons sur un <b>RPG 3D</b> dans le style de <i>Cube World</i>, mais revisité avec plusieurs mondes accessibles, plusieurs thèmes aux couleurs pastel et atmosphères. Le joueur explore un monde ouvert où il peut découvrir des villages, des ennemis sauvages, et des donjons.
                <br> <br>

                <u>Game et Level Design :</u> <b>Le joueur explore</b> un <b>monde ouvert</b> où il peut découvrir des villages, des ennemis sauvages et des donjons. Dans ces donjons se trouvent des séries d'ennemis et un boss final doté d'un pouvoir unique. <b>Lorsque le boss est vaincu, un portail de téléportation vers un nouveau monde apparaît</b>,<b> et le joueur gagne le pouvoir de ce boss qui lui permettra de survivre dans ce nouveau monde </b>(par exemple, le pouvoir de voler dans un monde aérien ou de respirer indéfiniment dans un monde aquatique). Ces nouveaux pouvoirs peuvent également permettre, à la manière d'un Metroidvania, d'accéder à des zones inaccessibles dans les anciens mondes. <b>L'exploration est la clé du jeu</b>, et la <b>curiosité du joueur</b> est largement récompensée.
                De plus, ces pouvoirs serviront également à atteindre de nouveaux lieux, trésors et quêtes secondaires désormais accessibles sur les anciens mondes, à la manière d'un <b>Metroid-Vania</b>.

                <br><br>J'ai préféré le Game Design à la modélisation 3D ! J'ai pris beaucoup de plaisir à écrire les atmosphères, le gameplay, les types de donjons, les personnages, les ennemis... J'ai écrit les tâches de chacun pour chaque zone. J'ai appris à faire des plans et à parler aux développeurs. En utilisant <b>Gitlab</b>, <b>Trello</b> et <b>Discord</b>, je me suis également formé à <b>Blender</b> et j'ai créé les premiers personnages et entités statiques du jeu tels que des arbres, des bancs, des buissons.

                <br><br>Le développement de ce premier prototype, a ainsi confirmé mon ambition professionnelle : Le Game Design et <u>l'envie de créer des jeux au cœur des envies des joueurs</u>.


              </v-card-text>
            </v-col>

          </v-row>
        </v-card>
      </v-card>
    </v-expand-x-transition>

    <!--    Cache-logo Spline    -->
    <v-card color="#030216"
            width="116"
            height="45 "
            style="position: fixed;bottom: 5px ;right: 6px;">
    </v-card>
  </div>
</template>

<script>
import Modal from "@/components/Modal";
import ModalVideo from "@/components/ModalVideo";

export default {
  name: 'HelloWorld',
  components:{
    Modal,
    ModalVideo,
  },
  props: {
    msg: String,
  },
  data: () => ({
    overlay: false,
    height: 50,
    hiddenTopLeft: false,
    hiddenTopRight: false,
    hiddenBottomLeft: false,
    image: {
      url: require("@/assets/Quentin Veillas.jpg"),
      lazy_url: require("@/assets/LQuentin Veillas.jpg"),
      name: "",
    },
    GGJLogo:{
      url: require("@/assets/GGJLogo.jpg"),
      lazy_url: require("@/assets/LGGJLogo.jpg"),
      name: "",
    },
    GGJLequipe:{
      url: require("@/assets/GGJLequipe.jpg"),
      lazy_url: require("@/assets/LGGJLequipe.jpg"),
      name: "",
    },
    GGJSC1:{
      url: require("@/assets/GGJSC1.jpg"),
      lazy_url: require("@/assets/LGGJSC1.jpg"),
      name: "",
    },
    GameplayGGJ:{
      url: require("@/assets/GameplayGGJ.mp4"),
      lazy_url: require("@/assets/LGameplayGGJ.mp4"),
      name: "",
    },
    SBBFinal:{
      url: require("@/assets/SBBFinal.png"),
      lazy_url: require("@/assets/LSBBFinal.png"),
      name: "",
    },
    SBBGif:{
      url: require("@/assets/SBBGif.gif"),
      lazy_url: require("@/assets/LSBBGif.gif"),
      name: "",
    },
    SBBSceno:{
      url: require("@/assets/SBBSceno.png"),
      lazy_url: require("@/assets/LSBBSceno.png"),
      name: "",
    },
    SBBTableauDeBord:{
      url: require("@/assets/SBBTableauDeBord.jpg"),
      lazy_url: require("@/assets/LSBBTableauDeBord.jpg"),
      name: "",
    },
    CATree3:{
      url: require("@/assets/CATree3.jpg"),
      lazy_url: require("@/assets/LCATree3.jpg"),
      name: "",
    },
    CATree3_Front:{
      url: require("@/assets/CATree3_Front.png"),
      lazy_url: require("@/assets/LCATree3_Front.png"),
      name: "",
    },
    CATree3_Side:{
      url: require("@/assets/CATree3_Side.png"),
      lazy_url: require("@/assets/LCATree3_Side.png"),
      name: "",
    },
    CAGameDesignPage:{
      url: require("@/assets/CAGameDesignPage.jpg"),
      lazy_url: require("@/assets/LCAGameDesignPage.jpg"),
      name: "",
    },
    WalkinMan:{
      url: require("@/assets/CAWalkingAnimation.gif"),
      lazy_url: require("@/assets/CAWalkingAnimation.gif"),
      name: "",
    },
    Chicken:{
      url: require("@/assets/CAChicken.gif"),
      lazy_url: require("@/assets/CAChicken.gif"),
      name: "",
    },


  }),
  methods: {
    async hiddenTopRightMethod () {
      if (this.hiddenTopLeft == true){
        this.hiddenTopLeft = false;
      }
      if (this.hiddenBottomLeft == true){
        this.hiddenBottomLeft = false;
      }
      this.hiddenTopRight = !this.hiddenTopRight;
    },
    async hiddenTopLeftMethod () {
      if (this.hiddenTopRight == true){
        this.hiddenTopRight = false;
      }
      if (this.hiddenBottomLeft == true){
        this.hiddenBottomLeft = false;
      }
      this.hiddenTopLeft = !this.hiddenTopLeft;
    },
    async hiddenBottomLeftMethod () {
      if (this.hiddenTopRight == true){
        this.hiddenTopRight = false;
      }
      if (this.hiddenTopLeft == true){
        this.hiddenTopLeft = false;
      }
      this.hiddenBottomLeft = !this.hiddenBottomLeft;
    },
    async resetMethod () {
      if (this.hiddenTopRight == true){
        this.hiddenTopRight = false;
      }
      if (this.hiddenTopLeft == true){
        this.hiddenTopLeft = false;
      }
      if (this.hiddenBottomLeft == true){
        this.hiddenBottomLeft = false;
      }
    },
  },

}

</script>



<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

.v-image.image--shadow {
  box-shadow: 0 6px 6px -3px rgba(0,0,0,.2),0 10px 14px 1px rgba(0,0,0,.14),0 4px 18px 3px rgba(0,0,0,.12)!important;
}

.lang{
  cursor : pointer;
}

.backG {
  position: fixed;
  background: #000;
  border: none;
  top: 0; right: 0;
  bottom: 0; left: 0;
  width: 100%;
  height: 100%;
}

.scroll {
  overflow-y: auto;
  overflow-x: hidden;
}

h1  {
  font-family: "Roboto", sans-serif;
}
h2  {
  font-family: "Roboto", sans-serif ;
}
h3 {
  font-family: "Roboto", sans-serif;
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #A8E6CE;
}

/* Scroll 5 */

::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}
::-webkit-scrollbar-track {
  border-radius: 7px;
  background-color: #030216 ;
}
::-webkit-scrollbar-thumb {
  background-image: linear-gradient(45deg, #AF54FB, #AF54FB);
  border-radius: 7px;
  -webkit-box-shadow: #030216;
}

.no-background-hover::before {
  background-color: transparent !important;
}

.myFont{
  font-family: "Futura Md BT" ;
}
</style>

<template>
  <v-app-bar app class="transparent" height="100"
             style=" padding: 0px; ">
    <v-spacer/>

    <!--        @click="$router.replace('/')" -->
    <v-hover v-slot="{ hover }" class="mt-1">
      <v-card
          class="mx-auto transparent mr-3 "
          max-width="200"
          min-width="100"
      >
        <v-img
            width="200"
            class="mt-1"
            height="80"
            src="@/assets/QuentinVeillas.png"
        >
          <v-img
              v-if="hover"
              style="top: 0.5px;"
              width="200"
              height="80"
              src="@/assets/QuentinVeillas.gif"

          >
          </v-img>
        </v-img>
      </v-card>
    </v-hover>
        <span color="#ffffff" style="font-size: 32px;" class="white--text ">|</span>

          <v-hover v-slot="{ hover }" class="mt-1">
            <v-card
                class="mx-auto transparent"
                max-width="200"
                min-width="100"
            >
              <v-img
                  width="200"
                  height="80"
                  src="@/assets/GDImg.png"
              >
                  <v-img
                      v-if="hover"
                      style="bottom: 1px;"
                      width="200"
                      height="80"
                      src="@/assets/GDGif.gif"

                  >
                  </v-img>
              </v-img>
            </v-card>
          </v-hover>

    <v-spacer/>

  </v-app-bar>
</template>

<script>
export default {
  name: "NavBar",
  computed: {
    options() {
      return {
        duration: 4000,
      }
    },
  },

  data: () => ({
    hidden: true,
    menu: [
      {name:'Qui suis-je ? ',goToPage: '/'},
      {name:'Mes Activités',goToPage: '/Activities'},
      {name:'Mon Book',goToPage: '/Book'},
      // {name:"Livre d'Or",goToPage: '/GoldenBook'},
    ],
  }),
}
</script>



<style scoped>
.v-image.image--shadow{

  box-shadow: 0px 4px 4px -5px rgba(0, 0, 0, 0.2), 0px 10px 12px 2px rgba(0, 0, 0, 0.14), 0px 3px 14px 3px rgba(0, 0, 0, 0.12);

}
</style>
<template>
  <div class="text-center" >
    <video v-if="this.$vuetify.breakpoint.width>this.$vuetify.breakpoint.height" :src="image.lazy_url"
           class="video--shadowhovered mx-auto"
           type="video/mp4"
           autoplay loop muted playsinline
           :width="this.$vuetify.breakpoint.width*0.15"
           @click="dialog = true"
           style="
           box-shadow: 0 6px 6px -3px rgba(0,0,0,.2),0 10px 14px 1px rgba(0,0,0,.14),0 4px 18px 3px rgba(0,0,0,.12)!important;
           max-width: 270px;
"
    />
    <video v-else :src="image.lazy_url"
           class="video--shadowhovered mx-auto"
           type="video/mp4"
           autoplay loop muted playsinline
           :width="this.$vuetify.breakpoint.width*0.4"
           @click="dialog = true"
           style="
           box-shadow: 0 6px 6px -3px rgba(0,0,0,.2),0 10px 14px 1px rgba(0,0,0,.14),0 4px 18px 3px rgba(0,0,0,.12)!important;

"
    ></video>
    <v-container v-if="image.name != ''">
      <v-card v-if="this.$vuetify.breakpoint.width > 1000" class="image--shadowhovered mt-2 mx-auto" color="white" max-width="300"> <v-card-text> {{image.name}} </v-card-text> </v-card>
      <p v-else class="mt-2" style="font-family:Serif; ">{{image.name}}</p>
    </v-container>

    <v-dialog
        v-model="dialog"
    >
      <video
          :src="image.url"
          :lazy-src="image.lazy_url"
          @click="dialog = false"
          :height="0.80*this.$vuetify.breakpoint.height"
          :width="0.80*this.$vuetify.breakpoint.width"
          contain
          type="video/mp4"
          autoplay loop muted playsinline
      >
      </video>

    </v-dialog>


  </div>
</template>

<script>
export default {
  name: "ModalVideo",
  data() {
    return {
      dialog: false,
    }
  },
  props: [
    'image'
  ]
}
</script>

<style >


.v-dialog {
  box-shadow: none !important;
}

video.video--shadowhovered {
  box-shadow: 0 6px 6px -3px rgba(0,0,0,.2),0 10px 14px 1px rgba(0,0,0,.14),0 4px 18px 3px rgba(0,0,0,.12)!important;
}

video.video--shadowhovered:hover{
  box-shadow: 0 11px 15px -7px rgba(0,0,0,.2),0 24px 38px 3px rgba(0,0,0,.14),0 9px 46px 8px rgba(0,0,0,.12)!important;
  cursor: pointer;
  margin-top: -15px!important;
  margin-bottom: 15px !important;
  transition: .3s cubic-bezier(.25,.8,.5,1)!important;
  transition-property: all !important;
  transition-duration: 0.3s !important;
  transition-timing-function: cubic-bezier(0.25, 0.8, 0.5, 1) !important;
  transition-delay: 0s !important;
}

</style>
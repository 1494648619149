import Vue from 'vue'
import VueRouter from 'vue-router'
import FR from "@/components/FR";
import EN from "@/components/EN"
/*
import Book from "@/vues/Book";*/

Vue.use(VueRouter)


const routes = [
    {
        path: "/",
        name: "FR",
        component: FR,
    },
    {
        path: "/en",
        name: "EN",
        component: EN,
    },
    /*
    {
        path: "/Book",
        name: "Book",
        component: Book,
    },*/


// -------------------- Error 404, at the end -------------------- //
    {
        path: '/:pathMatch(.*)*',
        component: () => import("@/vues/UrlSyntaxError"),
    },
]

const router = new VueRouter({
    routes: routes,
    mode: "history", // retour aux pages précédentes sans avoir à recharger les pages
    base: process.env.BASE_URL, // Clean root routes
})

export default router;
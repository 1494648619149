import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

Vuetify.config.silent = true;

export default new Vuetify({

    iconfont: 'fa', // 'mdi' || 'mdiSvg' || 'md' || 'fa' || 'fa4' || 'faSvg'
    theme: {
        themes: {
            light: {
                primary: '#36C892', //003F22 / 36C892
                secondary: '#FFFFFF',
                accent: '#82B1FF',
                error: '#FF5252',
                info: '#2196F3',
                success: '#4CAF50',
                warning: '#FFC107',
            },
        },
    },
    font:{

    }
});
